import './MapStyles.css';

const Map = () => {
    console.log("rendered map");

    return (
        <div className='map'>
        
        </div>
    )
}

export default Map;