import './BottomStyles.css'

function BottomPanel() {
    console.log("rendering bottom panel");
    return (
        <div>
            <h1>HELLO</h1>
        </div>  
    )
}

export default BottomPanel;